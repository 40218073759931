(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name home.user.controller:ChangePasswordCtrl
   *
   * @description
   *
   */
  angular
  .module('neo.home.user')
  .controller('ChangePasswordCtrl', ChangePasswordCtrl);

  function ChangePasswordCtrl(ChangePassword, $state, $mdToast, $filter) {
    var vm = this;
    vm.ctrlName = 'ChangePasswordCtrl';
    vm.changePasswordModel = {};

    vm.changePassword = function () {
      ChangePassword.save(vm.changePasswordModel,
        function () {
          $state.go('home', {}, {reload: true});
          $mdToast.show(
            $mdToast.simple()
              .textContent($filter('translate')('SUCCESSFUL_PASSWORD_CHANGE'))
            .position('bottom left')
            .hideDelay(5000)
          );
        }, function (error) {
          var errorMessage;
          if (error.status === 409) {
            errorMessage = 'INVALID_OLD_PASSWORD';
          } else {
            errorMessage = 'SERVER_ERROR';
          }
          $mdToast.show(
            $mdToast.simple()
            .textContent($filter('translate')(errorMessage))
            .position('bottom left')
            .hideDelay(5000)
          );
        });
    }
  }
}());
